(function($) {
    // Prototype
    function PropertySearch(container, options) {
        if (!this instanceof PropertySearch) {
            throw new SyntaxError("PropertySearch constructor called without 'new' keyword!");
        }

        var $container = $(container);

        if (!$container.length) {
            throw new ReferenceError("PropertySearch() - Argument #1. container selector matches no elements");
        }

        this.container_selector = container;
        this.$container = $container;
        this.container = $container[0];

        // merge the options in
        $.extend(this, options);

        this.init();
    }

    PropertySearch.prototype = {

        constructor: PropertySearch,

        // form
        _form: 'form#property-listing-filter-form',

        // selectors
        _searchPropertyForm: '#property-listing-filter-form',
        _searchPropertySectorSelect: 'select[name="property_sector"]',
        _searchPropertyListingSelect: 'input[type="radio"][name="property_rent_buy"]',
        _searchPropertyOrderBySelect: 'select.order-by',
        _searchPropertyOrderByHidden: '#property-filter-orderby',
        _searchPropertyTypeSelectStandard: '.property-type-select-standard',
        _searchPropertyTypeSelectResidential: '.property-type-select-residential',
        _searchPropertyPriceSelectRent: '.property-listing-select-rent',
        _searchPropertyPriceSelectBuy: '.property-listing-select-buy',
        _searchPropertyBedroomsContainer: '.property-bedrooms-select-container',

        init: function () {
            search = this;

            // form objects
            search.$form = $(search._form, search.container);

            // selector objects
            search.$searchPropertyForm = $(search._searchPropertyForm, search.container);
            search.$searchPropertySectorSelect = $(search._searchPropertySectorSelect, search.container);
            search.$searchPropertyListingSelect = $(search._searchPropertyListingSelect, search.container);
            search.$searchPropertyOrderBySelect = $(search._searchPropertyOrderBySelect, search.container);
            search.$searchPropertyOrderByHidden = $(search._searchPropertyOrderByHidden, search.container);
            search.$searchPropertyTypeSelectStandard = $(search._searchPropertyTypeSelectStandard, search.container);
            search.$searchPropertyTypeSelectResidential = $(search._searchPropertyTypeSelectResidential, search.container);
            search.$searchPropertyPriceSelectRent = $(search._searchPropertyPriceSelectRent, search.container);
            search.$searchPropertyPriceSelectBuy = $(search._searchPropertyPriceSelectBuy, search.container);
            search.$searchPropertyBedroomsContainer = $(search._searchPropertyBedroomsContainer, search.container);


            this.togglePropertyType();
            this.togglePropertyPrice();
            this.triggers();
        },

        triggers: function () {
            search.$searchPropertySectorSelect.on('change', function (e) {
                search.togglePropertyType();
            });

            search.$searchPropertyListingSelect.on('change', function (e) {
                search.togglePropertyPrice();
            });

            search.$searchPropertyOrderBySelect.on('change', function (e) {
                var orderby = search.getValue(search.$searchPropertyOrderBySelect);

                search.setValue(search.$searchPropertyOrderByHidden, orderby);
                search.$form.submit();
            });
        },

        togglePropertyType: function() {
            var type = search.getValue(search.$searchPropertySectorSelect);

            if ( type === 'residential' ) {
                search.$searchPropertyTypeSelectStandard.hide().prop('disabled', 'disabled');
                search.$searchPropertyTypeSelectResidential.show().prop('disabled', false);
                search.$searchPropertyBedroomsContainer.show().find('select').prop('disabled', false).val('all');
            } else {
                search.$searchPropertyTypeSelectStandard.show().prop('disabled', false);
                search.$searchPropertyTypeSelectResidential.hide().prop('disabled', 'disabled');
                search.$searchPropertyBedroomsContainer.hide().find('select').prop('disabled', 'disabled').val('all');
            }
        },

        togglePropertyPrice: function() {
            var listing = search.getValue($(search._searchPropertyListingSelect + ':checked'));

            if ( listing === 'property-to-buy' ) {
                search.$searchPropertyPriceSelectRent.hide().prop('disabled', 'disabled');
                search.$searchPropertyPriceSelectBuy.show().prop('disabled', false);
            } else {
                search.$searchPropertyPriceSelectRent.show().prop('disabled', false);
                search.$searchPropertyPriceSelectBuy.hide().prop('disabled', 'disabled');
            }
        },

        getValue: function (selector) {
            try {
                return selector.val();
            }
            catch(err) {
                return null;
            }

        },

        setValue: function (selector, value) {
            try {
                return selector.val(value);
            }
            catch(err) {
                return null;
            }

        }
    };

    $(window).load(function() {
        if ( $(".property-listings").length ) {
            window.quote = new PropertySearch('.property-listings');
        }
    });
})(jQuery);